import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
        0% {
            opacity:0;
        transform:translateY(4vh) scale(1);
        }
        50% {
        transform:translateY(-4vh) scale(1.2);
        }
        100% {
            opacity:1;
        transform:translateY(0vh) scale(1);
        }
`;
const Slider = styled.div`
  display: none;
  @media screen and (max-width: 760px) {
    display: block;
    max-width: 25rem;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    border-radius: 5px;
    animation: ${fadeIn} 0.4s linear forwards;
    box-shadow: 0 1px 20px #22222244;
    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }
`;
const Inner = styled.div`
  @media screen and (max-width: 760px) {
    overflow: visible;
    min-width: 700px;
    display: flex;
    gap: 2rem;
    border-radius: 5px;
  }
`;

const ImageContainer = styled.div`
  display: none;
  @media screen and (max-width: 760px) {
    width: 25rem;
    max-height: 23rem;
    display: flex;
    scroll-snap-align: center;
  }
`;

const Img = styled.img`
  width: 25rem;
  border-radius: 5px;
  object-fit: contain;
`;

const MobileSlider = ({ imgs }) => {
  return (
    <Slider>
      <Inner>
        {imgs.map((src: string, i: number) => {
          return (
            <ImageContainer key={i}>
              <Img src={src} alt={"carousel img"} />
            </ImageContainer>
          );
        })}
      </Inner>
    </Slider>
  );
};

MobileSlider.propTypes = {};

export default MobileSlider;
