import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import "@fontsource/montserrat";
import { AiOutlineArrowLeft } from "react-icons/ai";

const Open = keyframes`
    0% {
        max-height:0;
        opacity:0;
    }
    100% {
        max-height:1000vh;
        opacity:1;
    }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: #f4f7f5;
  padding-bottom: 6rem;
  animation: ${Open} 1s linear forwards;
`;

const ResumeBox = styled.div`
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  min-width: 1000px;
  max-width: 1200px;
  box-shadow: 0 1px 60px #22222233;
  padding: 1rem 0;
  @media screen and (max-width: 760px) {
    width: 90%;
  }
`;
const ButtonHeader = styled.div`
  width: 70%;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  @media screen and (max-width: 760px) {
    width: 90%;
  }
`;
const GoBackButton = styled.button`
  background: transparent;
  border: 0;
  color: #5000ad;
  &:hover {
    cursor: pointer;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Montserrat, helvetica, sans-serif;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  & h3 {
    color: #5000ad;
    text-transform: uppercase;
    margin: 0.2rem 0;
    font-family: Montserrat, helvetica, sans-serif;
  }
  & h4 {
    margin: 0.2rem 0;
  }
  & p {
    color: #666;
    margin: 0.2rem 0;
    font-family: Montserrat, helvetica, sans-serif;
  }
`;
const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border: 1px solid #222;
  width: 100%;
`;
const TableCol = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  & h4 {
    margin: 0.02rem 0;
  }
  & p {
    margin: 0rem 0;
    color: #999;
    font-size: 0.9rem;
  }
  border-right: 1px solid #222;
  &:last-of-type {
    border-right: 0px solid transparent;
  }
`;

const Resume = ({ showResume }) => {
  return (
    <Container>
      <ButtonHeader>
        <GoBackButton onClick={(e) => showResume(false)}>
          <AiOutlineArrowLeft /> Go Back
        </GoBackButton>
      </ButtonHeader>
      <ResumeBox>
        <Inner>
          <Header>
            <Row>
              <Col>
                <h3>Alex Rothenberg</h3>
                <p>Web Developer passionate about problem solving.</p>
              </Col>
              <Col>
                <p>Portfolio: https://alexshiresroth.com</p>
                <p>631-830-7642 | alex@alexshiresroth.com</p>
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: "1rem" }}>
                <h3>WEB DEVELOPER</h3>
                <p>
                  Developing unique and quality websites with modern
                  technologies. Seeking an opportunity that will allow me to
                  apply my skills and permit me to learn and grow my web
                  developer skill set.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>SKILLS</h3>
              </Col>
            </Row>
            <Row>
              <Table>
                <TableCol>
                  <h4>Front-End</h4>
                  <p>HTML/EJS</p>
                  <p>CSS/SASS/SCSS</p>
                  <p>JAVASCRIPT/ES6</p>
                  <p>WEBPACK/BABEL</p>
                  <p>TYPESCRIPT</p>
                  <p>STYLED COMPONENTS</p>
                  <p>React/Redux</p>
                  <p>SvelteJS</p>
                </TableCol>
                <TableCol>
                  <h4>Back-End</h4>
                  <p>NodeJs</p>
                  <p>NoSQL/MONGODB</p>
                  <p>Graphql</p>
                  <p>Apollo</p>
                </TableCol>
                <TableCol>
                  <h4>Source Control</h4>
                  <p>GIT</p>
                  <p>GITHUB</p>
                  <p>TRELLO</p>
                </TableCol>
                <TableCol>
                  <h4>Frameworks/Libraries</h4>
                  <p>Prettier</p>
                  <p>ESLINT</p>
                  <p>Express</p>
                  <p>Gatsby</p>
                  <p>NextJS</p>
                  <p>Graphql</p>
                </TableCol>
              </Table>
            </Row>
            <Row>
              <Col>
                <h3>Web Developer</h3>
                <p>Company: PopCrumbs</p>
                <p>Location: Melville, NY</p>
                <p>Start: 07/21/20 - Current Position</p>
                <p>
                  {" "}
                  <strong>Responsibilities:</strong>
                  <br /> Develop custom sales funnels for products
                  <br />
                  Maintain shopify stores
                  <br /> Design and produce ad banners and graphics for stores
                  <br /> Manage hosting of various sites
                  <br />
                  Create lead generation software
                  <br />
                  Write code to manage affiliate integration software.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>Projects</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>Clickfunnels & Everflow bridge API</h4>
                <p>
                  Successfully developed a solution to fix inaccurate data
                  sharing between clickfunnels and everflow software.
                </p>
                <p>Built with NodeJS, Everflow API and Stripe.</p>
                <a href="https://github.com/popcrumbs-alex/ef-api">Github</a>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>Winfree Advertising</h4>
                <p>
                  Developed a lead generation system for PopCrumbs' affiliate
                  network.
                </p>
                <p>Built with NodeJS, Graphql, Apollo, React and Redux.</p>
                <a
                  href="https://www.winfreeadvertising.com/"
                  target="_blank"
                  rel="noopener noreferer"
                >
                  Live Site
                </a>
                <a
                  href="https://github.com/popcrumbs-alex/lead-gen"
                  target="_blank"
                  rel="noopener noreferer"
                >
                  Github
                </a>
              </Col>
            </Row>
            <Row>
              <Col>
                {" "}
                <h4>Sales Funnels</h4>
                <p>
                  Constructed several sales funnels for certain products
                  residing on the Popcrumbs' shopify stores.
                </p>
                <p>
                  Built with NodeJS, Express, Shopify-buy, StripeJS, Paypal,
                  Braintree, React and Redux.
                </p>
                <a
                  href="https://slicklashes.com/"
                  target="_blank"
                  rel="noopener noreferer"
                >
                  Live Site
                </a>
                <a
                  href="https://github.com/popcrumbs-alex/slicklashes"
                  target="_blank"
                  rel="noopener noreferer"
                >
                  Github
                </a>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>Education</h3>
                <p>Baruch College / BA. Journalism and New Media Arts</p>
                <p>Self Taught / Web Development.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>Interests</h3>
                <p>
                  3D Modeling
                  <br /> Unreal Engine Game Development
                  <br /> C++
                </p>
              </Col>
            </Row>
          </Header>
        </Inner>
      </ResumeBox>
    </Container>
  );
};

Resume.propTypes = {};

export default Resume;
