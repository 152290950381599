import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { Colors } from "../../../constants/Colors";
import {
  dimensions,
  smallScreenWidth,
  widthPercent,
} from "../../../constants/Dimensions";
import Background3D from "./Background3D";
import Resume from "./Resume";

const FadeIn = keyframes`
  0%{
    opacity:0;
    transform:translateY(10vh);
  }
  80%{
    opacity:1;
    transform:translateY(-4vh);
  }
  100% {
    opacity:1;
    transoform:translateY(0vh);
  }

`;

const StyledHeader = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8rem 0;
  position: relative;
  background: ${Colors["light"].alpha + "37"};
  @media screen and (max-width: 760px) {
    padding: 1rem 0 5rem 0;
    max-width: 100%;
  }
`;
const HeaderContent = styled.div`
  width: ${widthPercent.percentage};
  max-width: ${dimensions.wideScreen};
  @media screen and (max-width: 760px) {
    width: ${smallScreenWidth.percentage};
  }
`;
const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation-delay: 0.5s;
  animation: ${FadeIn} 0.4s linear forwards;
  @media screen and (max-width: 760px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
`;
const AvatarContainer = styled.div`
  background-image: url("https://res.cloudinary.com/snackmanproductions/image/upload/v1617851212/portfolio/background-splat_fqykqd.png");
  overflow: visible;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 7rem;
  @media screen and (max-width: 760px) {
    padding: 5rem;
  }
`;
const Avatar = styled.img`
  object-fit: contain;
  border-radius: 100%;
  width: 15rem;
  height: 15rem;
  opacity: 0.87;
  box-shadow: 0 0 0 5px ${Colors["light"].tint};
  @media screen and (max-width: 760px) {
    width: 12rem;
    height: 12rem;
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  @media screen and (max-width: 760px) {
    margin-top: -2rem;
  }
`;
const H1 = styled.h1`
  font-weight: 900;
  font-size: 2.7rem;
  font-family: Raleway;
  margin: 0.5rem 0;
  @media screen and (max-width: 760px) {
    font-size: 1.5rem;
  }
`;
const H4 = styled.h4`
  font-family: Raleway;
  color: ${Colors["light"].tint};
  font-size: 1.2rem;
  line-height: 1.5;
  max-width: 80%;
  margin: 0.5rem 0;
  font-weight: 600;
  @media screen and (max-width: 760px) {
    font-size: 1rem;
    max-width: 100%;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  margin: 2rem 0;
  @media screen and (max-width: 760px) {
    margin: 0.5rem 0;
  }
`;

const Button = styled.button`
  padding: 1rem 2rem;
  background: ${(props) => props.color};
  border-radius: 5px;
  border: 0px;
  color: ${Colors["light"].background};
  margin: 0 1rem 0 0;
  box-shadow: 0 1px 30px #22222233;
  transition: all 0.3s ease;
  font-family: Raleway, sans-serif;
  font-size: 0.8rem;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 0 5px ${(props) => props.color + "6e"};
  }
  @media screen and (max-width: 760px) {
    padding: 1rem 1.5rem;
    display: ${(props) => props["data-remove-mobile"]};
  }
`;
const DownloadButton = styled.a`
  padding: 1rem 2rem;
  background: ${(props) => props.color};
  border-radius: 5px;
  border: 0px;
  color: ${Colors["light"].background};
  margin: 0 1rem 0 0;
  box-shadow: 0 1px 30px #22222233;
  font-family: Raleway, sans-serif;
  transition: all 0.3s ease;
  display: none;
  text-decoration: none;
  font-size: 0.8rem;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 0 5px ${(props) => props.color + "6e"};
  }
  @media screen and (max-width: 760px) {
    display: block;
  }
`;

const avatar = {
  src: "https://res.cloudinary.com/snackmanproductions/image/upload/v1617849783/portfolio/-wape11_bk1hmk.jpg",
};

const Header = () => {
  const handleRedirect = (browserLocation) => {
    return (window.location.href = browserLocation);
  };
  const [isResVisible, showResume] = useState<boolean>(false);

  if (isResVisible) {
    return <Resume showResume={showResume} />;
  }

  return (
    <StyledHeader>
      <Background3D />
      <HeaderContent>
        <Box>
          <AvatarContainer>
            <Avatar src={avatar.src} />
          </AvatarContainer>
          <TextContainer>
            <H1>
              Hey, I'm Alex Rothenberg,
              <br /> a fullstack dev from NY.
            </H1>
            <H4>
              I'm currently working @ PopCrumbs, where I create full stack
              e-commerce sites & lead generation software.
            </H4>
            <ButtonRow>
              <Button
                color={Colors["light"].tint}
                onPointerDown={(e) =>
                  handleRedirect("https://github.com/AlexShiresRoth")
                }
              >
                View Github
              </Button>
              <Button
                color={Colors["light"].primary}
                onClick={() => showResume(true)}
                data-remove-mobile="none"
              >
                View Resume
              </Button>
              <DownloadButton
                color={Colors["light"].primary}
                href="/resume.pdf"
                download="Alex_Rothenberg_Resume"
              >
                Download Resume
              </DownloadButton>
            </ButtonRow>
          </TextContainer>
        </Box>
      </HeaderContent>
    </StyledHeader>
  );
};

export default Header;
