import React, { useContext, useEffect, useState } from "react";
import { ReferenceContext } from "../../../pages";
import { useCustomRef } from "../../../hooks/useCustomRef";
import { useEvenOrOdd } from "../../../hooks/useEvenOrOdd";
import styled from "styled-components";
import {
  dimensions,
  smallScreenWidth,
  widthPercent,
} from "../../../constants/Dimensions";
import { SiUnrealengine, SiCplusplus, SiBlender } from "react-icons/si";
import { Colors } from "../../../constants/Colors";

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #fff;
  padding: 8rem 0;
`;

const Inner = styled.div`
  width: ${widthPercent.percentage};
  max-width: ${dimensions.wideScreen};
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 760px) {
    width: ${smallScreenWidth.percentage};
  }
`;
const Heading = styled.div`
  margin-top: -10rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (max-width: 760px) {
  }
`;
const H1 = styled.h1`
  font-weight: 700;
  font-size: 2.5rem;
  font-family: Raleway;
  margin: 0.5rem 0;
  background: #fff;
  padding: 1rem;
  box-shadow: 0 1px 30px #eee;
  @media screen and (max-width: 760px) {
    font-size: 1.5rem;
  }
`;

const ContentBox = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 6rem;
  align-items: center;
  width: 100%;
  justify-items: center;
  margin-top: 2rem;
  @media screen and (max-width: 760px) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2rem;
  border-radius: 10px;
  position: relative;
  & > video {
    object-fit: contain;
    width: 100%;
  }
`;

const IframeContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  margin-top: 2rem;
  border-radius: 10px;
  position: relative;
  padding: 1rem;
  & > iframe {
    object-fit: contain;
    width: 100%;
    max-height: 20rem;
    border-radius: 10px;
    z-index: 1;
    box-shadow: 0 1px 10px #22222211;
    height: 400px;
  }
  &:after {
    content: "";
    width: 105%;
    height: 300px;
    position: absolute;
    top: -10%;
    left: -5%;
    background: ${Colors["light"].danger};
    display: block;
    z-index: 0;
    padding: 1rem;
    border-radius: 10px;
    clip-path: polygon(0 0, 100% 0%, 100% 80%, 0% 100%);
  }
  @media screen and (max-width: 760px) {
    height: 300px;
    &:after {
      height: 200px;
    }
    & > iframe {
      height: 300px;
    }
  }
`;

const ProjectDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProjectHeading = styled.h2`
  font-family: Raleway;
  color: ${Colors["light"].tint};
  font-size: 2rem;
  margin: 0.5rem 0;
  @media screen and (max-width: 760px) {
    font-size: 1.4rem;
  }
`;
const SubHeading = styled.h4`
  font-family: Raleway;
  color: ${Colors["light"].tint + "86"};
  font-size: 1.2rem;
  margin: 0.5rem 0;
  @media screen and (max-width: 760px) {
    font-size: 0.9rem;
  }
`;

const Disclaimer = styled.span`
  font-family: Raleway;
  font-style: italic;
  background: ${Colors["light"].danger + "51"};
  border-radius: 5px;
  padding: 0.7rem;
  font-size: 0.8rem;
  color: #fff;
  margin: 0.5rem 0;
`;

const Description = styled.p`
  font-family: Raleway;
  color: ${Colors["light"].text};
  max-width: 90%;
  font-weight: 700;
  line-height: 1.7;
  margin: 0.5rem 0;
`;

const Assets = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AssetGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  width: 100%;
  margin-top: 3rem;
  @media screen and (max-width: 760px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  padding: 1rem 2rem;
  position: relative;
  box-shadow: 0 1px 20px #22222214;
  &:after {
    content: "";
    width: 100%;
    height: 150px;
    position: absolute;
    top: 0%;
    left: 0%;
    background: ${Colors["light"].danger};
    display: block;
    z-index: 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    clip-path: polygon(0 0, 100% 0%, 100% 80%, 0% 100%);
  }
  & svg {
    color: #fafafa;
    padding: 2rem;
    border-radius: 100%;
    z-index: 1;
  }
  & p {
    font-family: Raleway;
    font-size: 1.3rem;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    color: ${Colors["light"].text};
    z-index: 1;
  }
`;

const Hobby = () => {
  const { refs, setRefs } = useContext(ReferenceContext);

  const [sectionRef, setRef] = useState(null);

  const [isEven, setValue] = useState(false);

  const [ref] = useCustomRef(setRef);

  const handleExistance = (arr: Array<any>, key: string, item: string) => {
    console.log(arr, key, item);
    return arr.filter((el: object) => el[key] === item).length > 0;
  };

  useEffect(() => {
    if (sectionRef) {
      if (!handleExistance(refs, "section", "hobby")) {
        setRefs((prevState) => [
          ...prevState,
          { section: "hobby", ref: sectionRef },
        ]);
      }
    }
  }, [sectionRef]);

  useEffect(() => {
    if (refs) useEvenOrOdd(refs, "section", "hobby", setValue);
  }, [refs]);

  return (
    <Section>
      <Inner ref={ref}>
        <Heading>
          <H1>Hobby</H1>
        </Heading>
        <ContentBox>
          <VideoContainer>
            <IframeContainer
              style={{
                position: "relative",
              }}
            >
              <iframe
                src="https://www.loom.com/embed/0636790d4a6f422b95827dc578db6a55"
                frameBorder="0"
                allowFullScreen={true}
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                }}
              ></iframe>
            </IframeContainer>
          </VideoContainer>
          <ProjectDescription>
            <ProjectHeading>A Town Named Nowhere</ProjectHeading>
            <SubHeading>Unreal Engine 4 Project</SubHeading>
            <Disclaimer>
              This project is currently in development. Stay tuned for the
              release!
            </Disclaimer>
            <Description>
              ATNN takes place in an isolated town during dust-bowl era US. The
              objective is to collect all your belongings before the storm
              returns and consumes the town(and you).
            </Description>
          </ProjectDescription>
        </ContentBox>
        <Assets>
          <AssetGrid>
            <Col>
              <SiUnrealengine size={55} />
              <p>Built In Unreal Engine 4</p>
            </Col>
            <Col>
              <SiBlender size={55} />
              <p>3D Modeling using Blender & Assets from ue4 marketplace</p>
            </Col>
            <Col>
              <SiCplusplus size={55} />
              <p>Created with C++ and Blueprints</p>
            </Col>
          </AssetGrid>
        </Assets>
      </Inner>
    </Section>
  );
};

export default Hobby;
