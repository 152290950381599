import React, { Suspense, useRef } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { ContactShadows } from "@react-three/drei/core/ContactShadows";
import styled from "styled-components";
import * as THREE from "three";

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #f4f7f5;
  overflow: hidden;
  width: 100%;
  z-index: -1;
  top:0%;
`;

function Mesh() {
  const mesh = useRef();
  const gltf = useLoader(GLTFLoader, "/laptop.gltf");

  useFrame(({ clock }) => {
    const t = clock.getElapsedTime();
    mesh.current.rotation.y = -180;
    // mesh.current.rotation.z = rotation / 2;
    mesh.current.rotation.x = THREE.MathUtils.lerp(
      mesh.current.rotation.x,
      Math.cos(t / 2) / 10 + 0.25,
      0.1
    );
    mesh.current.rotation.y = THREE.MathUtils.lerp(
      mesh.current.rotation.y,
      Math.sin(t / 4) / 10,
      0.1
    );
    mesh.current.rotation.z = THREE.MathUtils.lerp(
      mesh.current.rotation.z,
      Math.sin(t / 4) / 20,
      0.1
    );
    mesh.current.position.y = THREE.MathUtils.lerp(
      mesh.current.position.y,
      (-7 + Math.sin(t)) / 5,
      0.1
    );
  });

  return <primitive object={gltf.scene} ref={mesh} scale={1} />;
}

const Background3D = () => {
  return (
    <Container>
      <Canvas
        style={{ height: "100vh", width: "100%" }}
        dpr={[1, 2]}
        camera={{ position: [10, 0, -25], fov: 25 }}
      >
        <ambientLight intensity={0.2} />
        <directionalLight />
        <Suspense fallback={null}>
          <Mesh />
        </Suspense>
        <ContactShadows
          rotation-x={Math.PI / 2}
          position={[0, -4.5, 0]}
          opacity={1}
          width={20}
          height={20}
          blur={2}
          far={4.5}
        />
      </Canvas>
    </Container>
  );
};

export default Background3D;
