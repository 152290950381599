import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { Colors, TechColors } from "../../../constants/Colors";
import MobileSlider from "./MobileSlider";

const fadeIn = keyframes`
0% {
	opacity:0;
  transform:translateY(4vh) scale(1);
}
50% {
  transform:translateY(-4vh) scale(1.2);
}
100% {
	opacity:1;
  transform:translateY(0vh) scale(1);
}
`;
const Project = styled.div`
  display: flex;
  flex-direction: column;
`;
const ProjectContent = styled.div`
  display: flex;
  width: 100%;
  margin: 4rem 0 2rem 0;
  @media screen and (max-width: 760px) {
    flex-direction: column;
    margin: 1rem 0;
  }
`;
const ProjectCarousel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
  @media screen and (max-width: 760px) {
    display: none;
  }
`;

const CarouselImg = styled.img`
	width: 23rem;
	object-fit: contain;
  transition:all .3s ease;
	&:hover {
	  transform: scale(1.5);
		cursor: zoom-in;
    }
	}
  @media screen and (max-width:760px) {
    display:none;
  }
`;

const ImageContainer = styled.div`
  max-width: 23rem;
  display: flex;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 1px 40px #ccc;
  transition: all 0.3s ease;
  opacity: 0;
  &:nth-child(1) {
    animation: ${fadeIn} 0.4s 0.2s linear forwards;
  }
  &:nth-child(2) {
    animation: ${fadeIn} 0.4s 0.3s linear forwards;
  }
  &:nth-child(3) {
    animation: ${fadeIn} 0.4s 0.4s linear forwards;
  }
  @media screen and (max-width: 760px) {
    display: none;
  }
`;
const MainImage = styled.img`
  max-width: 30rem;
  border-radius: 20px;
  box-shadow: 0 1px 60px #ccc;
  transition: all 0.3s ease;
  opacity: 0;
  animation: ${fadeIn} 0.4s linear forwards;
  &:hover {
    transform: scale(1.2);
    cursor: zoom-in;
  }
  @media screen and (max-width: 760px) {
    display: none;
  }
`;
const ProjectDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0rem 0 4rem;
  opacity: 0;
  animation: ${fadeIn} 0.4s 0.5s linear forwards;
  & > h4 {
    font-family: Raleway;
    color: ${Colors["light"].tint};
    font-size: 2rem;
    margin: 0.5rem 0;
  }
  & p {
    font-family: Raleway;
    color: ${Colors["light"].text};
    max-width: 90%;
    font-weight: 700;
    line-height: 1.7;
    margin: 0.5rem 0;
  }
  @media screen and (max-width: 760px) {
    margin: 1rem 0;
    & > h4 {
      font-size: 1.4rem;
    }
    & p {
      max-width: 100%;
    }
  }
`;

const TechBubbleRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem 0;
  @media screen and (max-width: 760px) {
    width: 100%;
    justify-content: space-between;
  }
`;
const Bubble = styled.div`
  background: ${(props) => props.color};
  color: #fff;
  padding: 0.5rem 1rem;
  margin: 0.7rem 0.8rem 0 0;
  font-family: Raleway;
  font-size: 0.9rem;
  border-radius: 20px;
  font-weight: 500;
  box-shadow: 0 0px 0 5px ${(props) => props.color + "6e"};
  @media screen and (max-width: 760px) {
    margin: 1rem 0.5rem 0 0;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  margin: 0.5rem 0;
  margin-top: 1.5rem;
`;

const Button = styled.button`
  padding: 0.7rem 1.5rem;
  background: ${(props) => props.color};
  border-radius: 5px;
  border: 0px;
  color: ${(props) => props.datatype};
  margin: 0 1rem 0 0;
  box-shadow: 0 1px 30px #eee;
  transition: all 0.3s ease;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 0 5px ${(props) => props.color + "6e"};
  }
`;

const ProjectGrid = ({
  data: {
    title,
    text,
    mainImage,
    imgs,
    tech,
    links: { github, liveSite },
  },
}) => {
  const handleNavOut = (route: string) => {
    return window.open(route, "_blank");
  };

  const allImages = [mainImage, ...imgs];

  return (
    <Project>
      <ProjectContent>
        <MainImage src={mainImage} alt="Main project img" />
        <MobileSlider imgs={allImages} />
        <ProjectDescription>
          <h4>{title}</h4>
          <p>{text}</p>
          <TechBubbleRow>
            {tech.map((tech: string, i: number) => {
              console.log(TechColors[tech.toLowerCase()]);
              return (
                <Bubble
                  key={i}
                  color={TechColors[tech.toLowerCase()].background}
                >
                  {tech}
                </Bubble>
              );
            })}
          </TechBubbleRow>
          <ButtonRow>
            <Button
              color={Colors["light"].tint}
              datatype={Colors["light"].background}
              onPointerDown={() => handleNavOut(github)}
            >
              View Github
            </Button>
            <Button
              color={Colors["light"].primary}
              datatype={Colors["light"].background}
              onPointerDown={() => handleNavOut(liveSite)}
            >
              View Live
            </Button>
          </ButtonRow>
        </ProjectDescription>
      </ProjectContent>
      <ProjectCarousel>
        {imgs.map((src: string, i: number) => (
          <ImageContainer key={i}>
            <CarouselImg src={src} alt="carousel img" />
          </ImageContainer>
        ))}
      </ProjectCarousel>
    </Project>
  );
};

ProjectGrid.propTypes = {};

export default ProjectGrid;
