import React, { useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Colors } from "../../../constants/Colors";
import "@fontsource/raleway";
import {
  dimensions,
  smallScreenWidth,
  widthPercent,
} from "../../../constants/Dimensions";
import { ReferenceContext } from "../../../pages/index";

const SlideDown = keyframes`
  0% {
    opacity:0;
    transform:translateY(-10vh);
  }
  100% {
    opacity:1;
    transform:translateY(0);
  }
`;

const NavBar = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  border-bottom: 1.3px solid ${Colors["light"].alpha};
  padding: 0.5rem 0;
  position: ${(props) => props["data-type-nav"]};
  box-shadow: 0 1px 20px
    ${(props) =>
      props["data-type-nav"] === "fixed" ? "#22222211" : "transparent"};
  top: 0;
  left: 0;
  transition: all 0.3s ease;
  background: #fff;
  z-index: 999;
  animation-delay: 0.3s;
  animation: ${SlideDown} 0.4s linear forwards;
`;
const NavInner = styled.div`
  width: ${widthPercent.percentage};
  max-width: ${dimensions.wideScreen};
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 760px) {
    width: ${smallScreenWidth.percentage};
  }
`;

const NavLogo = styled.img`
  object-fit: contain;
  max-width: 4rem;
  @media screen and (max-width: 760px) {
    width: 2rem;
    max-height: 4rem;
  }
`;

const NavUrls = styled.ul`
  padding: 0;
  display: flex;
`;
const StyledLi = styled.li`
  list-style: none;
  font-family: Raleway, sans-serif;
  margin: 0 1rem;
  transition: all 0.3s ease;
  padding: 0.5rem;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.color + "11"};
  }
  @media screen and (max-width: 760px) {
    margin: 0 0.5rem;
  }
`;

const logo = {
  src: "https://res.cloudinary.com/snackmanproductions/image/upload/v1617847538/portfolio/personal-logo_ov7ljz.png",
};

const Nav = () => {
  const { refs, setRefs } = useContext(ReferenceContext);

  const [isScrolling, setScrolling] = useState<boolean>(false);

  const [navLinks, setLinks] = useState([]);
  const handleScrollToSection = (ref: any) => {
    if (ref) {
      ref.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 20) setScrolling(true);
      if (window.pageYOffset < 20) setScrolling(false);
    });
  });

  useEffect(() => {
    setLinks(refs);
  }, [refs]);

  return (
    <NavBar data-type-nav={isScrolling ? "fixed" : "relative"}>
      <NavInner>
        <NavLogo src={logo.src} />
        <NavUrls>
          {refs &&
            refs.length > 0 &&
            refs.map((ref: any, index) => {
              return (
                <StyledLi
                  onPointerDown={() => handleScrollToSection(ref.ref)}
                  color={Colors["light"].primary}
                  key={index}
                >
                  <a>{ref.section}</a>
                </StyledLi>
              );
            })}
        </NavUrls>
      </NavInner>
    </NavBar>
  );
};

export default Nav;
