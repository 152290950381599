import React, { useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import { AiOutlineClose, AiOutlineMail } from "react-icons/ai";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { Colors } from "../../../constants/Colors";

const spin = keyframes`
    0%{
      transform: rotate(0deg);
    }
    100%{
      transform:rotate(360deg);
    }`;

const fadeIn = keyframes`
  0% {
    opacity:0;
    max-width:0;
  }
  100% {
    opacity:1;
    max-width:100%;
  }
`;

const ContactModal = styled.div`
  position: fixed;
  bottom: 3%;
  right: 3%;
  z-index: 99999;
  background: ${(props) => props.color || "#5000ad33"};
  border-radius: ${(props) => props["data-border-radius"] || "1000px"};
  border-bottom-right-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 20px #66666633;
  width: ${(props) => props["data-width"] || "4.5rem"};
  height: ${(props) => props["data-height"] || "4.5rem"};
  transition: all 0.5s ease-in-out;
  & svg {
    color: #fff;
  }
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 760px) {
    max-width: 95%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  transition: all 0.5s ease-in-out;
  @media screen and (max-width: 760px) {
    padding: 0.5rem;
  }
`;

const FormRow = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem 0;
`;
const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled.label`
  font-family: Raleway;
  margin-bottom: 0.4rem;
  opacity: 0;
  animation: ${fadeIn} 0.5s linear forwards;
`;
const Input = styled.input`
  border: 0;
  border-radius: 3px;
  background: #eee;
  padding: 1rem 0;
  text-indent: 10px;
  font-size: 16px;
  opacity: 0;
  animation: ${fadeIn} 0.5s linear forwards;
  @media screen and (max-width: 760px) {
    padding: 0.8rem 0.4rem;
  }
`;
const TextArea = styled.textarea`
  resize: none;
  border: 0;
  border-radius: 3px;
  background: #eee;
  min-height: 5rem;
  font-size: 16px;
  opacity: 0;
  text-indent: 10px;
  padding: 10px;
  animation: ${fadeIn} 0.5s linear forwards;
  @media screen and (max-width: 760px) {
    padding: 0.4rem;
  }
`;
const SubmitBtn = styled.button`
  width: 100%;
  height: 2.7rem;
  background: #5000ad;
  border: 0;
  color: #fff;
  border-radius: 3px;
  font-family: Raleway;
  font-size: 20px;
  justify-self: flex-end;
  opacity: 0;
  animation: ${fadeIn} 0.5s linear forwards;
  &:hover {
    cursor: pointer;
  }
`;

const CloseBtn = styled.button`
  position: absolute;
  bottom: 100%;
  right: 0;
  background: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-family: Raleway;
  display: flex;
  align-items: center;
  text-align: center;
  border: 0;
  background: #eee;
  color: #666;
  padding: 0.5rem 1rem;
  &:hover {
    cursor: pointer;
  }
  & svg {
    color: #666;
  }
`;

const LoadingSpinner = styled.div`
  display: block;
  height: 2.5rem;
  width: 2.5rem;
  border: 5px solid #5000ad33;
  border-top: 5px solid #5000ad;
  border-radius: 100%;
  animation: ${spin} 0.5s linear infinite;
`;

const Alert = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${Colors["light"].danger};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0;
  z-index: 9999;
  & p {
    color: #fff;
    font-family: Raleway;
  }
  & button {
    margin-left: 2rem;
    background: transparent;
    border: 2px solid #fff;
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
    &:hover {
      cursor: pointer;
      background: #fff;
      color: ${Colors["light"].danger};
    }
  }
`;

const SentContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 10px;
  padding: 0 2rem;
  min-height: 90%;
  & h4 {
    font-family: Raleway;
    font-size: 2.4rem;
    margin: 0.5rem;
    color: #666;
  }
  & div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }
`;

type Email = {
  name: string;
  email: string;
  message: string;
};

type Success = {
  sent: boolean;
  messageResponse: string;
};

const Contact = () => {
  const [formVisible, setVisibility] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [error, setError] = useState<string>("");

  const [success, setSuccess] = useState<Success>({
    sent: false,
    messageResponse: "",
  });

  const { sent, messageResponse } = success;

  const [data, setData] = useState<Email>({
    name: "",
    email: "",
    message: "",
  });

  const { name, email, message } = data;

  const onChange = (
    e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>
  ) => setData({ ...data, [e.currentTarget.name]: e.currentTarget.value });

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const production = "https://asrserver.herokuapp.com";
    try {
      const res = await axios({
        method: "POST",
        url: `${production}/api/portfolio/send-email`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ...data,
        },
      });
      setLoading(false);
      console.log("response", res);
      setError("");
      setSuccess({
        sent: true,
        messageResponse: res.data.msg,
      });
      setData({ email: "", name: "", message: "" });
    } catch (error) {
      console.error("error", error.message);
      setLoading(false);
      setError(error.message);
    }
  };

  const reset = () => {
    setError("");
    setSuccess({
      sent: false,
      messageResponse: "",
    });
  };

  if (formVisible && sent) {
    return (
      <ContactModal
        color="#fff"
        data-width="30rem"
        data-height="30rem"
        data-border-radius="5px"
      >
        {" "}
        <CloseBtn
          onClick={() => setVisibility(false)}
          onTouchEnd={() => setVisibility(false)}
        >
          Close
          <AiOutlineClose size={16} />
        </CloseBtn>
        <SentContent>
          <div>
            <AiOutlineMail size={45} color={"#7BAE7F"} />
            <h4>{messageResponse}</h4>
          </div>
          <SubmitBtn onClick={reset}>Send Another Message</SubmitBtn>
        </SentContent>
      </ContactModal>
    );
  }

  if (formVisible) {
    return (
      <ContactModal
        color="#fff"
        data-width="30rem"
        data-height="30rem"
        data-border-radius="5px"
      >
        <CloseBtn
          onClick={() => setVisibility(false)}
          onTouchEnd={() => setVisibility(false)}
        >
          Close
          <AiOutlineClose size={16} />
        </CloseBtn>
        {error && (
          <Alert>
            <p>{error}</p>
            <button onClick={reset}>
              Close <AiOutlineClose />
            </button>
          </Alert>
        )}
        <Form onSubmit={submitForm}>
          <FormRow>
            <FormColumn>
              <Label>Name</Label>
              <Input
                type="text"
                placeholder="Your Name"
                name="name"
                value={name}
                onChange={onChange}
                required={true}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <Label>Email</Label>
              <Input
                type="email"
                placeholder="Your Email"
                name="email"
                value={email}
                onChange={onChange}
                required={true}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <Label>Message</Label>
              <TextArea
                placeholder="Please write out a brief message, thanks!"
                name="message"
                value={message}
                onChange={onChange}
                required={true}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            {loading && <LoadingSpinner />}
            {!loading && <SubmitBtn onSubmit={submitForm}>Submit</SubmitBtn>}
          </FormRow>
        </Form>
      </ContactModal>
    );
  }
  return (
    <ContactModal
      onClick={() => setVisibility(true)}
      onTouchEnd={() => setVisibility(true)}
    >
      <FaEnvelope size={30} />
    </ContactModal>
  );
};

export default Contact;
