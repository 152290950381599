import React, { useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useCustomRef } from "../../../hooks/useCustomRef";
import { ReferenceContext } from "../../../pages";
import { useEvenOrOdd } from "../../../hooks/useEvenOrOdd";
import {
  dimensions,
  smallScreenWidth,
  widthPercent,
} from "../../../constants/Dimensions";
import { graphql, useStaticQuery } from "gatsby";
import { FiAlertCircle } from "react-icons/fi";
import "@fontsource/raleway";
import RepoCard from "./RepoCard";

const spin = keyframes`
0%{
  transform: rotate(0deg);
}
100%{
  transform:rotate(360deg);
}`;

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f4f7f5;
  padding: 8rem 0;
`;
const Inner = styled.div`
  width: ${widthPercent.percentage};
  max-width: ${dimensions.wideScreen};
  display: flex;
  flex-direction: column;
  position: relative;
  @media screen and (max-width: 760px) {
    width: ${smallScreenWidth.percentage};
  }
`;

const Disclaimer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 20rem;
  &:hover {
    cursor: pointer;
  }
  & p {
    padding: 1rem 2rem;
    background: #eeeeee44;
    color: #5000ad;
    margin: 0.4rem 0;
    font-family: Raleway;
    & svg {
      margin: 0 0.5rem;
    }
  }
`;
const Box = styled.div`
  max-width: 25rem;
  position: absolute;
  background: #fff;
  box-shadow: 0 1px 10px #66666644;
  bottom: 110%;
  left: 0;
  opacity: 1;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  padding: 0.5rem;
  & p {
    color: #666;
    font-family: Raleway;
    letter-spacing: 0.3px;
    line-height: 1.3;
    margin: 0;
    background: #fff;
    z-index: 1;
  }
  & span {
    position: absolute;
    bottom: -10%;
    left: 5%;
    width: 0;
    height: 0;
    z-index: 0;
    border-radius: 10px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fff;
  }
`;

const BoxHidden = styled.div`
  opacity: 0;
  z-index: -9999999;
  visibility: hidden;
  max-width: 0;
  position: absolute;
  transition: all 0.3s ease-in-out;
`;

const Heading = styled.div`
  margin-top: -10rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  @media screen and (max-width: 760px) {
    justify-content: flex-start;
  }
`;
const H1 = styled.h1`
  font-weight: 700;
  font-size: 2.5rem;
  font-family: Raleway;
  margin: 0.5rem 0;
  background: #fff;
  padding: 1rem;
  box-shadow: 0 1px 30px #eee;
  @media screen and (max-width: 760px) {
    font-size: 1.5rem;
  }
`;
const WorkContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 4rem;
  margin: 4rem 0;
  @media screen and (max-width: 760px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
`;

const LoadMoreButton = styled.button`
  border-radius: 60px;
  width: 10rem;
  height: 3rem;
  background: #5000ad;
  color: #fff;
  border: 0;
  transition: all 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 3px #5000ad33;
  }
`;

const LoadingSpinner = styled.div`
  display: block;
  height: 2.5rem;
  width: 2.5rem;
  border: 5px solid #5000ad33;
  border-top: 5px solid #5000ad;
  border-radius: 100%;
  animation: ${spin} 0.5s linear infinite;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Work = () => {
  const { refs, setRefs } = useContext(ReferenceContext);

  const [visible, setVisibility] = useState<boolean>(false);

  const { github } = useStaticQuery(graphql`
    query MyQuery($first: Int = 15) {
      github {
        user(login: "popcrumbs-alex") {
          repositories(first: 15) {
            totalCount
            edges {
              node {
                languages(
                  first: $first
                  orderBy: { field: SIZE, direction: ASC }
                ) {
                  edges {
                    node {
                      color
                      id
                      name
                    }
                  }
                }
                name
                url
                isPrivate
                description
              }
            }
          }
        }
      }
    }
  `);

  const [sectionRef, setRef] = useState<undefined>(null);

  const [max, setMax] = useState<number>(4);

  const [loadingRepos, loadMore] = useState<boolean>(false);

  const [isEven, setValue] = useState<boolean>(false);

  const [repos, setRepos] = useState<Array<any>>([]);

  const [ref] = useCustomRef(setRef);

  const expandRepoAmt = () => {
    loadMore(true);
    setTimeout(() => {
      loadMore(false);
      setMax((prevAmt: number) => prevAmt + 4);
    }, 2000);
  };

  const handleExistance = (arr: Array<any>, key: string, item: string) => {
    return arr.filter((el: object) => el[key] === item).length > 0;
  };

  useEffect(() => {
    if (sectionRef) {
      if (!handleExistance(refs, "section", "work")) {
        setRefs((prevState) => [
          ...prevState,
          { section: "work", ref: sectionRef },
        ]);
      }
    }
  }, [sectionRef]);

  useEffect(() => {
    if (refs) useEvenOrOdd(refs, "section", "work", setValue);
  }, [refs]);

  useEffect(() => {
    if (!github) {
      if (github.user?.repositories.edges.length > 0) {
        setRepos((prevState: Array<any>) => [
          ...github.user?.repositories.edges.reverse(),
        ]);
      }
    }
  }, [github]);

  if (!github || !github.user) {
    return <p>Loading...</p>;
  }

  console.log("repo", github.user?.repositories.edges);
  return (
    <Section>
      <Inner ref={ref}>
        <Heading>
          <H1>Work</H1>
        </Heading>
        <Disclaimer
          onMouseEnter={() => setVisibility(true)}
          onMouseLeave={() => setVisibility(false)}
          onClick={() => setVisibility(!visible)}
        >
          {visible ? (
            <Box>
              <p>
                The work produced in these repositories do not reflect any of my
                politcal views or moral beliefs. These are strictly projects I
                had to create for my current position.
              </p>
              <span></span>
            </Box>
          ) : (
            <BoxHidden></BoxHidden>
          )}
          <p>
            <FiAlertCircle size={14} />
            Disclaimer
          </p>
        </Disclaimer>
        <WorkContainer>
          {github.user?.repositories.edges
            .map((repo: any, index: number) => {
              return <RepoCard repo={repo} index={index} />;
            })
            .reverse()
            .slice(0, max)}
        </WorkContainer>
        <LoadingContainer>
          {loadingRepos && <LoadingSpinner />}
          {max <= github.user?.repositories.edges.length && !loadingRepos && (
            <LoadMoreButton onClick={() => expandRepoAmt()}>
              Load More
            </LoadMoreButton>
          )}
        </LoadingContainer>
      </Inner>
    </Section>
  );
};

export default Work;
