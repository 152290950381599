import React from "react";
import PropTypes from "prop-types";
import { LayoutParamList } from "../../types/types";
import { createGlobalStyle } from "styled-components";
import styled from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin:0;
  }
  `;
const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  @media screen and (max-width: 760px) {
    max-width: 100%;
  }
`;

const Layout = ({ children }: LayoutParamList) => {
  return (
    <Main>
      <GlobalStyle />
      {children}
    </Main>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
