export const dimensions = {
  wideScreen: "1200px",
};
export const widthPercent = {
  percentage: "80%",
};

export const smallScreenWidth = {
  percentage: "90%",
};
