import * as React from "react";
import Layout from "../components/layout/Layout";
import Nav from "../components/layout/nav/Nav";
import Header from "../components/layout/header/Header";
import Projects from "../components/layout/projects/Projects";
import Work from "../components/layout/work/Work";
import Footer from "../components/layout/footer/Footer";
import Contact from "../components/layout/contact/Contact";
import Hobby from "../components/layout/hobby/Hobby";
// markup
export const ReferenceContext = React.createContext({
  refs: [],
  setRefs: null,
});

const IndexPage = () => {
  const [refs, setRefs] = React.useState<any>([]);
  const value = { refs, setRefs };
  return (
    <ReferenceContext.Provider value={value}>
      <Layout>
        <Nav />
        <Header />
        <Projects />
        <Work />
        <Hobby />
        <Footer />
        <Contact />
      </Layout>
    </ReferenceContext.Provider>
  );
};

export default IndexPage;
