import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { FaArrowRight, FaGithub, FaLock, FaLockOpen } from "react-icons/fa";
import "@fontsource/raleway";
import AccessRequest from "./AccessRequest";

const Repo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 1px 20px #22222233;
  padding: 2rem;
  min-width: 300px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  background: #fff;
  @media screen and (max-width: 760px) {
    max-width: 90%;
    margin: 1rem 0;
  }
`;
const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  padding: 1rem;
  background: #fff;
  dispay: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 50px;
  & svg {
    color: #5000ad;
  }
`;

const RepoHead = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
`;
const GradientBackground = styled.div`
  position: absolute;
  height: 100px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  background: linear-gradient(
    90deg,
    ${(props) => props["data-color-one"]} 0%,
    ${(props) => props["data-color-two"]} 50%,
    ${(props) => props["data-color-three"]} 100%
  );
`;

const RepoTitle = styled.h2`
  font-family: Raleway;
  z-index: 2;
  padding: 1rem;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 10px #22222222;
`;

const Languages = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const RepoLang = styled.p`
  background: ${(props) => props.color};
  font-family: Raleway;
  margin: 0 0.3rem 0 0;
  padding: 0.5rem;
  color: #fff;
  border-radius: 3px;
`;

const PrivateIndicator = styled.div`
  & p {
    font-family: Raleway;
  }
`;

const ButtonContainer = styled.div`
  position: relative;
`;

const RepoBtn = styled.button`
  width:10rem;
  height:2.8rem;
  border-radius: 60px;
  border:3px solid #fff;
  color: #666;
  text-decoration: none;
  font-family: Raleway;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position:relative;
  overflow:hidden;

  & p {
    font-weight: 800;
    margin: 0;
    transition: all .3s ease-in-out;
    z-index:2;
  }
  & svg {
    z-index:2;
    transition:all .3s ease-in-out;
  }
  &::after {
    height:120%;
    width:120%;
    max-width:0;
    content:"";
    position:absolute;
    top:0;
    left:0;
    z-index:1;
    background: linear-gradient( 90deg,
        ${(props) => props["data-color-one"]} 0%,
        ${(props) => props["data-color-two"]} 50%,
        ${(props) => props["data-color-three"]} 100%
      );
    transition:all .3s ease-in-out;
  }
    &:hover {
        cursor: pointer;
        & p {
          color:#fff;
        }
        & svg {
          color:#fff;
        }
        &::after{
          max-width:120%;
        }
      
      }
    }`;

const Description = styled.p`
  font-family: Raleway;
  color: #666;
  margin: 1rem 0;
`;

const RepoCard = ({ index, repo }) => {
  const [accessRepoRequested, requestAccess] = useState<boolean>(false);
  const [colors, setColors] = useState<Array<any>>([]);
  const [repoIsPrivate, hideRepo] = useState<boolean>(false);

  const accessRepo = (node: { isPrivate: boolean; url: any }) => {
    if (node.isPrivate) {
      console.log("Someone has requested access to this repo");

      return;
    }
    return (window.location = node.url);
  };

  const handleColorGradient = () => {
    const colorStrs = repo.node.languages.edges.map(
      (lang: any) => lang.node.color
    );

    colorStrs.forEach((color: string, i: number) => {
      const j = Math.floor(Math.random() * i);
      const temp = colorStrs[i];
      colorStrs[i] = colorStrs[j];
      colorStrs[j] = temp;
    });

    setColors(colorStrs);
  };

  useMemo(() => {
    if (repo) {
      handleColorGradient();
    }
  }, [repo]);

  return (
    <Repo key={index}>
      <IconContainer>
        <FaGithub />
      </IconContainer>
      <RepoHead>
        <RepoTitle>{repo.node.name}</RepoTitle>
        <GradientBackground
          data-color-one={colors[0] || "#833ab4"}
          data-color-two={colors[1] || "#fd1d1d"}
          data-color-three={colors[2] || "#fcb045"}
        />
      </RepoHead>
      <Languages>
        {repo.node.languages.edges.map((lang: any, i: number) => {
          return <RepoLang color={lang.node.color}>{lang.node.name}</RepoLang>;
        })}
      </Languages>
      <Description>{repo.node.description}</Description>
      <PrivateIndicator>
        {repo.node.isPrivate ? (
          <p>
            Private <FaLock size={14} />
          </p>
        ) : (
          <p>
            Public <FaLockOpen size={14} />
          </p>
        )}
      </PrivateIndicator>
      <ButtonContainer>
        <AccessRequest
          requestAccess={requestAccess}
          accessRequested={accessRepoRequested}
          repoName={repo.node.name}
        />
        <RepoBtn
          onClick={() => {
            accessRepo(repo.node);
            requestAccess(true);
          }}
          data-color-one={colors[0] || "#833ab4"}
          data-color-two={colors[1] || "#fd1d1d"}
          data-color-three={colors[2] || "#fcb045"}
        >
          {repo.node.isPrivate ? <p>Request Access</p> : <p>View</p>}
          <FaArrowRight size={14} />
        </RepoBtn>
      </ButtonContainer>
    </Repo>
  );
};

export default RepoCard;
