import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FaChevronRight } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";

const RequestContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  position: absolute;
  bottom: 100%;
  left: 0;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 1px 30px #00000033;
  z-index: 999;
  font-family: Raleway;
  transition: all 0.5s ease-in-out;
  & span {
    position: absolute;
    top: 93%;
    left: 0;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fff;
    border-radius: 10px;
  }
`;
const Close = styled.button`
  display: flex;
  position: absolute;
  bottom: 100%;
  padding: 0.5rem;
  right: 0;
  background: red;
  border: 0;
  color: #fff;
  border-radius: 3px;
  &:hover {
    cursor: pointer;
  }
`;
const Inner = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  & p {
    font-size: 0.9rem;
    margin: 0.5rem 0;
    & b {
      color: #5000ad;
    }
  }
`;
const Label = styled.label`
  font-size: 0.9rem;
  margin-left: 0.5rem;
`;
const Input = styled.input`
  padding: 1rem;
  text-index: 10px;
  border: 0;
  background: #eee;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
  &:focus {
    outline: 0 solid transparent;
    box-shadow: 0 1px 3px #5000ad66;
  }
`;
const InputRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
    box-shadow: 0 1px 3px ${(props) => props.color + "66"};
    background: ${(props) => props["data-type-alt-color"]};
    border: 0;
    border-radius: 3px;
    color: ${(props) => props.color};
    width: 3rem;
    height: 3rem;
    transition: all 0.3s ease-in-out;
    &:hover {
      cursor: pointer;
    }
  }
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0.5rem 0;
`;

const AccessRequest = ({ requestAccess, accessRequested, repoName }) => {
  const [data, setData] = useState<any>({ email: "" });

  const { email } = data;

  const onChange = (e: React.FormEvent<HTMLInputElement>) =>
    setData({ ...data, [e.target.name]: e.target.value });

  if (!accessRequested) {
    return null;
  }
  return (
    <RequestContainer>
      <span></span>
      <Close onClick={() => requestAccess(false)}>
        <AiOutlineClose />
      </Close>
      <Inner>
        <Form>
          <p>
            Requesting access for <b>{repoName}</b>
          </p>
          <InputContainer>
            <Label>Email</Label>
            <InputRow
              color={email !== "" ? "#5000ad" : "#fff"}
              data-type-alt-color={email !== "" ? "#fff" : "#eee"}
            >
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                name="email"
                onChange={(e) => onChange(e)}
              />
              <button>
                <FaChevronRight />
              </button>
            </InputRow>
          </InputContainer>
        </Form>
      </Inner>
    </RequestContainer>
  );
};

AccessRequest.propTypes = {};

export default AccessRequest;
