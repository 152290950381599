export const Colors = {
  light: {
    background: "#FFF",
    primary: "#5000ad",
    tint: "#111222",
    alpha: "#F4F7F5",
    text: "#666",
    danger:"#b0413e"
  },
  dark: {
    background: "#112",
    primary: "#5000ad",
    tint: "",
    alpha: "",
    text: "",
  },
};

export const TechColors = {
  react: {
    background: "#00A6FB",
  },
  redux: {
    background: "#D33F49",
  },
  node: {
    background: "#00A9A5",
  },
  shopify: {
    background: "#FFC482",
  },
  typescript: {
    background: "#9C95DC",
  },
  mongodb: {
    background: "#255957",
  },
};
