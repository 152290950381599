import React from "react";
import styled from "styled-components";

const Section = styled.footer`
  background: #f4f7f5;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Content = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled.p`
  color: #666;
  font-family: Raleway;
  font-size: 14px;
`;

const Email = styled.a`
  text-decoration: none;
  color: #666;
  font-family: Raleway;
  font-size: 14px;
`;

const Footer = () => {
  const date = new Date();

  return (
    <Section>
      <Content>
        <Text>Alex Roth &copy; {date.getFullYear()}</Text>
        <Email href="mailto:alex@alexshiresroth.com">
          alex@alexshiresroth.com
        </Email>
      </Content>
    </Section>
  );
};

export default Footer;
