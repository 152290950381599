import * as React from "react";
import { SetStateAction, useCallback, useRef } from "react";

export const useCustomRef = (callback: any) => {
  const ref = useRef(null);
  const setRef = useCallback((node) => {
    if (ref.current) {
      // Make sure to cleanup any events/references added to the last instance
      console.log("this is a ref", ref.current);
      callback(ref.current);
    }

    if (node) {
      // Check if a node is actually passed. Otherwise node would be null.
      // You can now do what you need to, addEventListeners, measure, etc.
      console.log("this is a node", node);
      callback(node);
    }

    // Save a reference to the node
    ref.current = node;
  }, []);

  return [setRef];
};
