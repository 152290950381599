//used to determine if section ref is even or odd for the placement of the title block
export const useEvenOrOdd = (
  arr: Array<any>,
  key: string,
  str: string,
  callback: Function
) => {
  const index = arr
    .map((item, i) => {
      return item[key] === str ? i : null;
    })
    .filter((index) => index + 1)[0];

  return callback(index % 2 === 0);
};
