import React, { useContext, useEffect, useState } from "react";

import styled from "styled-components";
import ProjectGrid from "./ProjectGrid";
import { Colors } from "../../../constants/Colors";
import "@fontsource/raleway";
import {
  dimensions,
  smallScreenWidth,
  widthPercent,
} from "../../../constants/Dimensions";
import { ReferenceContext } from "../../../pages/index";
import { useCustomRef } from "../../../hooks/useCustomRef";
import { useEvenOrOdd } from "../../../hooks/useEvenOrOdd";

const projectData = [
  {
    title: "atownnamednowhere",
    text: `An ecommerce site for the band Crash the Calm's new album "A Town Named Nowhere".`,
    mainImage:
      "https://res.cloudinary.com/snackmanproductions/image/upload/c_scale,q_60,w_1608/v1629425029/crash/2021-08-19_1_kju7wr.jpg",
    imgs: [
      "https://res.cloudinary.com/snackmanproductions/image/upload/c_scale,q_65,w_1012/v1618871409/portfolio/Screenshot_2021-04-16_16.30.45_ofsovo.jpg",
      `https://res.cloudinary.com/snackmanproductions/image/upload/c_scale,q_63,w_1023/v1618871548/portfolio/2021-04-08_2_h9ojwm.jpg`,
      "https://res.cloudinary.com/snackmanproductions/image/upload/c_scale,q_64,w_932/v1618871691/portfolio/2021-04-08_1_ctajdn.jpg",
    ],
    tech: ["React", "Typescript", "Redux", "Node", "Shopify", "MongoDB"],
    links: {
      github: "https://github.com/AlexShiresRoth/ctc-app",
      liveSite: "https://atownnamednowhere.com/",
    },
  },
  {
    title: "satactsense",
    text: `Website for an SAT/ACT tutoring company called SATACTSense. `,
    mainImage:
      "https://res.cloudinary.com/snackmanproductions/image/upload/c_scale,q_67,w_1008/v1618872296/portfolio/2021-04-19_btrgvg.jpg",
    imgs: [
      "https://res.cloudinary.com/snackmanproductions/image/upload/c_scale,q_65,w_928/v1618869752/portfolio/2021-04-19_1_xjijcw.jpg",
      `https://res.cloudinary.com/snackmanproductions/image/upload/c_scale,q_64,w_707/v1618869752/portfolio/2021-04-19_2_hwux9n.jpg`,
      "https://res.cloudinary.com/snackmanproductions/image/upload/c_scale,q_64,w_1498/v1629425870/satactsense/2021-08-19_2_suprmg.jpg",
    ],
    tech: ["React", "Typescript", "Redux", "Node"],
    links: {
      github: "https://github.com/AlexShiresRoth/satactsense",
      liveSite: "https://www.satactsense.com/",
    },
  },
  {
    title: "italianobros",
    text: `This is a website for a construction company, built with GatsbyJS`,
    mainImage:
      "https://res.cloudinary.com/snackmanproductions/image/upload/c_scale,q_63,w_1411/v1629510951/portfolio/2021-08-20_3_hgiqfq.jpg",
    imgs: [
      "https://res.cloudinary.com/snackmanproductions/image/upload/c_scale,q_60,w_1476/v1629510952/portfolio/2021-08-20_4_lecvfx.jpg",
      `https://res.cloudinary.com/snackmanproductions/image/upload/c_scale,q_64,w_1411/v1629510963/portfolio/2021-08-20_5_rzlzr3.jpg`,
      "https://res.cloudinary.com/snackmanproductions/image/upload/c_scale,q_61,w_1433/v1629511059/portfolio/2021-08-20_6_slloav.jpg",
    ],
    tech: ["React", "Redux", "Node"],
    links: {
      github: "https://github.com/AlexShiresRoth/ctc-app",
      liveSite: "https://www.italianobrosenterprise.com/",
    },
  },
];

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #fff;
  padding: 8rem 0;
`;

const Inner = styled.div`
  width: ${widthPercent.percentage};
  max-width: ${dimensions.wideScreen};
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 760px) {
    width: ${smallScreenWidth.percentage};
  }
`;

const Heading = styled.div`
  margin-top: -10rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (max-width: 760px) {
  }
`;
const H1 = styled.h1`
  font-weight: 700;
  font-size: 2.5rem;
  font-family: Raleway;
  margin: 0.5rem 0;
  background: #fff;
  padding: 1rem;
  z-index: 3;
  box-shadow: 0 1px 30px #eee;
  @media screen and (max-width: 760px) {
    font-size: 1.5rem;
  }
`;
const ProjectIndexes = styled.div`
  display: flex;
  margin-top: 2rem;
  width: 100%;
  @media screen and (max-width: 760px) {
    margin: 2rem 0;
  }
`;
const PaginateBtn = styled.button`
  background: ${(props) => props.color};
  border-radius: 5px;
  border: 0;
  padding: 1rem;
  margin: 0 1rem 0 0;
  box-shadow: 0 1px 30px #ccc;
  font-family: Raleway;
  font-weight: 700;
  color: ${(props) => props.datatype};
  transition: all 0.3s ease;
  &:hover {
    cursor: pointer;
  }
`;

const Projects = () => {
  const [index, chooseIndex] = useState<number>(0);

  const { refs, setRefs } = useContext(ReferenceContext);

  const [sectionRef, setRef] = useState(null);

  const [isEven, setValue] = useState(false);

  const [ref] = useCustomRef(setRef);

  const handleExistance = (arr: Array<any>, key: string, item: string) => {
    console.log(arr, key, item);
    return arr.filter((el: object) => el[key] === item).length > 0;
  };

  useEffect(() => {
    if (sectionRef) {
      console.log(
        "handling existance",
        handleExistance(refs, "section", "freelance")
      );
      if (!handleExistance(refs, "section", "freelance")) {
        setRefs((prevState) => [
          ...prevState,
          { section: "freelance", ref: sectionRef },
        ]);
      }
    }
  }, [sectionRef]);

  useEffect(() => {
    if (refs) useEvenOrOdd(refs, "section", "freelance", setValue);
  }, [refs]);

  return (
    <Section>
      <Inner ref={ref}>
        <Heading>
          <H1>Freelance</H1>
        </Heading>
        <ProjectIndexes>
          {projectData.map((_, i) => {
            return (
              <PaginateBtn
                onTouchEnd={(e) => chooseIndex(i)}
                onClick={() => chooseIndex(i)}
                color={
                  index === i
                    ? Colors["light"].tint
                    : Colors["light"].background
                }
                datatype={
                  index === i
                    ? Colors["light"].background
                    : Colors["light"].tint
                }
                key={i}
              >
                Project:{i + 1}
              </PaginateBtn>
            );
          })}
        </ProjectIndexes>
        {
          projectData.map((data, key) => {
            return <ProjectGrid data={data} key={key} />;
          })[index]
        }
      </Inner>
    </Section>
  );
};

export default Projects;
